import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BlockContainer = styled.div`
  margin-bottom: 70px;
`;

const Title = styled.div`
  border: 1px solid #ccc;
  padding: 10px 40px;
  display: inline-block;
  text-align: center;
  background-color: var(--main-background);
`;

const TitleContainer = styled.div`
  text-align: center;
  color: #466672;
  border-bottom: 1px solid #e0e0e0;
  padding: 0;
  margin: 29px 0 69px;
  height: 26px;
  overflow: visible;
  position: relative;
`;

const Block = ({ children, title }) => (
  <BlockContainer>
    {
      title && (
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
      )
    }
    <div>
      {children}
    </div>
  </BlockContainer>
);

Block.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Block.defaultProps = {
  title: null,
};

export default Block;
