import React from 'react';
import PropTypes from 'prop-types';
import Obfuscate from 'react-obfuscate';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Block from '../../components/block/Block';
import messages from '../../i18n/messages';
import Container from '../../components/block/Container';

const { contact } = messages;

// const Screen = styled.div`
//   width: 100%;
// `;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 40px 0 80px;
`;

const ObfuscateStyled = styled(Obfuscate)`
  color: var(--article-datetime);
  pointer-events: none;
`;

const Email = styled.div`
  margin: 6px 0;
`;

const TextContainer = styled.div`
  width: 80%;
  text-align: center;
  margin: 20px 0;
`;

const ContactMe = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO title="Cyril Planchon" />
    <Container>
      <Block title={formatMessage({ id: 'menu@contact' })}>
        <InnerContainer>
          <TextContainer>
            <FormattedMessage {...contact.contactMe} />
            <Email>
              <ObfuscateStyled
                email="cyril.planchon@free.fr"
              />
            </Email>
          </TextContainer>
          <TextContainer>
            <FormattedMessage {...contact.contactAgent} />
            <Email>
              <ObfuscateStyled
                email="contact@myagency.fr"
              />
            </Email>
          </TextContainer>
        </InnerContainer>
      </Block>
    </Container>
  </Layout>
);

ContactMe.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(ContactMe);
